import React from 'react';
import styled from 'styled-components';

const RoomsContainer = styled.div`
  padding: 20px;
`;

const RoomsHeader = styled.h1`
  color: #ffffff;
`;

const RoomsPage = () => {
  return (
    <RoomsContainer>
      <RoomsHeader>Rooms</RoomsHeader>
      <p>This is a placeholder for the rooms page. We'll add more details here later!</p>
    </RoomsContainer>
  );
};

export default RoomsPage;