// Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  background-color: #000000;
  color: white;
  padding: 1rem;
  text-align: center;
  position: sticky;
  top: ${props => props.$sticky ? '0' : '-100px'};
  transition: top 0.3s;
  z-index: 100;
`;

function Header({ refreshPosts }) {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.pageYOffset > window.innerHeight * 0.2);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleResetAndNavigate = () => {
    refreshPosts();
    // If not at home, navigate to home
    // Scroll to top
    window.scrollTo(0, 0);
  };

  return (
    <HeaderContainer $sticky={sticky}>
      <Link to="/" onClick={handleResetAndNavigate}>
        <img src="https://www.iiicoast.com/backend-static/upload/apple-touch-icon-v2.gif" alt="Logo" width="35px" />
      </Link>
    </HeaderContainer>
  );
}

export default Header;