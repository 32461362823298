import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import useWindowSize from '../hooks/useWindowSize';
import { BarLoader } from 'react-spinners';

const ChatbotContainer = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ChatbotHeader = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  img {
    height: 30px;
    margin-right: 10px;
  }
  h1 {
    color: #ffffff;
  }
`;

const ChatBox = styled.div`
  height: 400px;
  overflow-y: auto;
  border-radius: 8px;
  background-color: #070707;
  padding: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const MessageInput = styled.input`
  font-size: 16px;
  flex: 1;
  padding: 5px 10px;
`;

const SendButton = styled.button`
  font-size: 16px;
  border-radius: 0 10px 10px 0;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
`;

const ChatMessage = styled.div`
  display: ${props => props.isUser ? 'flex' : 'flex'};
  justify-content: ${props => props.isUser ? 'flex-end' : 'flex-start'};
  margin-bottom: 10px;
`;

const MessageContent = styled.div`
  background-color: ${props => props.isUser ? '#007bff' : '#151515'};
  color: ${props => props.isUser ? 'white' : 'white'};
  padding: 5px 10px;
  border-radius: 10px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ChatbotPage = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const chatBoxRef = useRef(null);
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  useEffect(() => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  }, [messages]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() !== '') {
      setIsLoading(true);
      setMessages([
        ...messages,
        { isUser: true, content: inputValue }
      ]);
      setInputValue('');
      try {
        const response = await axios.post('/v2/get_response', {
          user_input: inputValue
        });
        setMessages([
          ...messages,
          { isUser: true, content: inputValue },
          { isUser: false, content: response.data.response }
        ]);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
      // Hide virtual keyboard on mobile
      if (isMobile) {
        e.target.blur();
      }
    }
  };

  return (
    <ChatbotContainer>
      <ChatbotHeader>
        <img src="https://emoji.discadia.com/emojis/2cfe66d1-92e1-4479-b169-0fef08fb5b96.PNG" alt="Chatbot" />
        <h1>Chatbot</h1>
      </ChatbotHeader>
      <ChatBox ref={chatBoxRef}>
        {messages.map((message, index) => (
          <ChatMessage key={index} isUser={message.isUser}>
            <MessageContent isUser={message.isUser}>{message.content}</MessageContent>
          </ChatMessage>
        ))}
        {isLoading && (
        <SpinnerContainer>
          <BarLoader color="#888888" loading={isLoading} />
        </SpinnerContainer>
        )}
      </ChatBox>
      <InputContainer>
        <MessageInput
          type="text"
          placeholder="Enter a message"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <SendButton onClick={handleSendMessage}>Send</SendButton>
      </InputContainer>
    </ChatbotContainer>
  );
};

export default ChatbotPage;