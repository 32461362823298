import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Post from '../components/Post'; // Import the new Post component
import { FaSearch } from 'react-icons/fa'; //, FaCog
import { Link } from 'react-router-dom';

const ExploreContainer = styled.div`
  padding: 20px;
`;

const ExploreHeader = styled.h2`
  color: #ffffff;
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  background-color: #212121;
  font-size: 16px;
  width: 55%;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #333;
  margin-right: 8px;
`;

const SearchButton = styled.button`
  padding: 8px 16px;
  // background-color: #007bff;
  font-size: 16px;
  background: linear-gradient(to right, rgb(13, 104, 210), rgb(63, 111, 275), rgb(43, 91, 255));
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-size: 200% auto;
  transition: background-position 0.4s ease-in-out;

  &:hover {
    // background-color: #0056b3;
    background-position: right center;
  }
`;

const UserContainer = styled.div`
  border-bottom: 1px solid #222;
  padding: 12px 0;
`;

const UserLink = styled(Link)`
  color: #fff;
  text-decoration: none;

  img {
    vertical-align: middle;
    margin-right: 8px;
  }
`;

const PostListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 15px;  // Tighter layout on mobile
  }
`;

const MutedText = styled.p`
  color: #777;
`

const ExplorePage = () => {
  const [searchResults, setSearchResults] = useState({
    users: [],
    posts: []
  });

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    axios.get(`/api/search?query=${encodeURIComponent(searchQuery)}`)
      .then(response => {
        setSearchResults({
          users: response.data.users,
          posts: response.data.posts
        });
      })
      .catch(error => {
        console.error('Error searching:', error);
      });
  };

  return (
    <ExploreContainer>
      <ExploreHeader>Explore</ExploreHeader>
      <SearchContainer>
        <SearchInput 
          type="text" 
          placeholder="Search..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <SearchButton onClick={handleSearch}><FaSearch /> Search</SearchButton>
      </SearchContainer>
      <div>
        <h4>Users</h4>
        {searchResults.users.length > 0 ? (
          searchResults.users.map(user => (
            <UserContainer key={user.uid}>
              <UserLink to={`/profile/${user.username}`}>
                <img src={`https://www.iiicoast.com/${user.profile_picture_url}`} alt="Profile" width="35" height="35" />
                {user.username}
              </UserLink>
            </UserContainer>
          ))
        ) : (
          <MutedText>No users found.</MutedText>
        )}
        <h4>Posts</h4>
        <PostListContainer>
        {searchResults.posts.length > 0 ? (
          
          searchResults.posts.map(post => (
            <Post key={post.id} post={post} />
          ))
          
        ) : (
          <MutedText>No posts found.</MutedText>
        )}
        </PostListContainer>
      </div>
    </ExploreContainer>
  );
};

export default ExplorePage;