import React, { useContext, useEffect, useState } from 'react';
import { AxiosContext } from '../App';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import styled from 'styled-components';
import UserPostList from '../components/UserPostList';
import { FaPencilAlt, FaPlus, FaTimes } from 'react-icons/fa';

// Styled components
const ProfileCard = styled.div`
  background-color: #070707;
  margin: 15px;
  // border: 5px solid #070707;
  border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Optional: adds a shadow for a lifted card effect

  @media (max-width: 768px) {
    margin: 5px;
  }
`;

const ProfileContainer = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const CoverPhoto = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
`;

const ProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: -75px;
  border: 5px solid #fff; // Adjust the border color as needed
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const ProfileHeader = styled.h5`
  margin-top: 15px;
  color: #fff; // Adjust the color as needed
`;

const BioText = styled.p`
  color: #999; // Adjust the color as needed
`;

const FormContainer = styled.div`
  margin-top: 20px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledInput = styled.input`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const StyledInput2 = styled.input`
  font-size: 16px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const StyledButton = styled.button`
  padding: 0.75rem;
  background: linear-gradient(to right, #0d68d2, #53a3ff, #2b5bff);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  background-size: 200% auto;
  transition: background-position 0.4s ease-in-out;

  &:hover {
    background-position: right center;
  }
`;

const EditButton = styled.button`
  background: none;
  border: none;
  color: #198754;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 10px;
`;

const ProfilePictureContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ProfilePictureEditButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #198754;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ${props => props.$isOpen ? 'translateY(0)' : 'translateY(20px)'};
  background-color: #070707;
  color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: all 0.3s ease-in-out;
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: opacity 0.3s ease;
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  z-index: 1000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
`;

const ProfilePage = () => {
  const axiosInstance = useContext(AxiosContext);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [newBio, setNewBio] = useState('');
  const [newProfilePicture, setNewProfilePicture] = useState(null);

  const [isProfilePictureModalOpen, setIsProfilePictureModalOpen] = useState(false);
  const [isBioModalOpen, setIsBioModalOpen] = useState(false);

  const openProfilePictureModal = () => setIsProfilePictureModalOpen(true);
  const closeProfilePictureModal = () => setIsProfilePictureModalOpen(false);
  const openBioModal = () => setIsBioModalOpen(true);
  const closeBioModal = () => setIsBioModalOpen(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await axiosInstance.get('/v2/user-profile');
      setUserData(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch user profile');
      setLoading(false);
      if (err.response && err.response.status === 401) {
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    if (userData) {
      const coverPhoto = document.querySelector('.cover-photo');
      const profilePicture = document.querySelector('.profile_picture');

      gsap.from(coverPhoto, { opacity: 0, y: -50, duration: 0.6, ease: "power1.out" });
      gsap.from(profilePicture, { opacity: 0, scale: 0.5, duration: 0.8, delay: 0.3, ease: "elastic.out(1, 0.3)" });
    }
  }, [userData]);

  // const user = JSON.parse(localStorage.getItem('user'));

  const handleBioSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/v2/update_bio', { bio: newBio });
      fetchUserProfile();
      setNewBio('');
      closeBioModal();
    } catch (err) {
      setError('Failed to update bio');
    }
  };

  const handleProfilePictureSubmit = async (e) => {
    e.preventDefault();
    if (!newProfilePicture) return;

    const formData = new FormData();
    formData.append('photo', newProfilePicture);

    try {
      await axiosInstance.post('/v2/update_profile_picture', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      fetchUserProfile();
      setNewProfilePicture(null);
      closeProfilePictureModal();
    } catch (err) {
      setError('Failed to update profile picture');
    }
  };

  if (!userData) {
    return <p>User data not found</p>;
  }

  return (
    <ProfileCard>
      <ProfileContainer>
        <CoverPhoto src="https://picsum.photos/1600/400?random=cover" alt="Cover Photo" className="cover-photo" />
        <div style={{ textAlign: 'center' }}>
          <ProfilePictureContainer>
            <ProfilePicture src={`https://www.iiicoast.com/backend-static/${userData.profile_picture_url}`} alt="Profile" className="profile_picture" />
            <ProfilePictureEditButton onClick={openProfilePictureModal}>
              <FaPlus />
            </ProfilePictureEditButton>
          </ProfilePictureContainer>
          <ProfileHeader>{userData.username}'s Bio</ProfileHeader>
          <BioText>
            {userData.bio}
            <EditButton onClick={openBioModal}>
              <FaPencilAlt />
            </EditButton>
          </BioText>
        </div>
      <UserPostList username={userData.username} />
      </ProfileContainer>
      

      <Backdrop $isOpen={isProfilePictureModalOpen || isBioModalOpen} onClick={() => {
        closeProfilePictureModal();
        closeBioModal();
      }} />

      <Modal $isOpen={isProfilePictureModalOpen}>
        <CloseButton onClick={closeProfilePictureModal}>
          <FaTimes />
        </CloseButton>
        <h2>Update Profile Picture</h2>
        <StyledForm onSubmit={handleProfilePictureSubmit}>
          <StyledInput
            type="file"
            onChange={(e) => setNewProfilePicture(e.target.files[0])}
            accept="image/*"
          />
          <StyledButton type="submit">Update Profile Picture</StyledButton>
        </StyledForm>
      </Modal>

      <Modal $isOpen={isBioModalOpen}>
        <CloseButton onClick={closeBioModal}>
          <FaTimes />
        </CloseButton>
        <h2>Update Bio</h2>
        <StyledForm onSubmit={handleBioSubmit}>
          <StyledInput2
            type="text"
            value={newBio}
            onChange={(e) => setNewBio(e.target.value)}
            placeholder="Update your bio"
          />
          <StyledButton type="submit">Update Bio</StyledButton>
        </StyledForm>
      </Modal>
    </ProfileCard>
  );
};

export default ProfilePage;
