import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from './AuthContext';
import axiosInstance from './axiosConfig';
import useLongPolling from './hooks/useLongPolling'; // Adjust path accordingly
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import PostList from './components/PostList';
import ProfilePage from './pages/ProfilePage';
import ViewProfilePage from './pages/ViewProfilePage';
import ExplorePage from './pages/ExplorePage';
import Sidebar from './components/Sidebar';
import NotificationTray from './components/NotificationTray';
import FloatingActionButton from './components/FloatingActionButton';
import Login from './components/Login';
import Register from './components/Register';
import Onboarding from './components/Onboarding';
import MusicPage from './pages/MusicPage';
import ChatbotPage from './pages/ChatbotPage';
import RoomsPage from './pages/RoomsPage';
import MessagingPage from './pages/MessagingPage';
import ViewThread from './components/ViewThread';
import ViewArticle from './components/ViewArticle';
import ProtectedRoute from './components/ProtectedRoute';
import { BarLoader } from 'react-spinners';


// Styled Loader Component
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
  color: white;
`;

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PageContent = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: ${props => props.$sidebarMinimized ? '60px' : '240px'};
  transition: margin-left 0.3s ease;
  max-width: 800px;

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    margin-left: ${props => props.$sidebarMinimized ? '80px' : '260px'};
    margin-right: ${props => props.$sidebarMinimized ? '20px' : '0'};
  }
`;

const NewPostButton = styled.button`
  position: fixed;
  top: 80px;            /* Adjust this value for vertical positioning */
  left: 50%;
  transform: translateX(-50%);  /* Center the button horizontally */
  z-index: 1000;        /* Ensure it appears above other elements */
  padding: 10px 20px;
  background-color: #007bff; /* Blue background */
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
`;

const NewPostsAlert = ({ onClick }) => (
  <NewPostButton onClick={onClick}>
    New Posts Available
  </NewPostButton>
);

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;  // Center content horizontally
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  max-width: 800px;  // Maximum width for desktops
  width: 100%;       // Full width on mobile

  @media (min-width: 1200px) {
    padding: 20px 40px;  // More padding for larger screens
  }

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

export const AxiosContext = React.createContext(null);

function App() {
  const [sidebarMinimized, setSidebarMinimized] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [returningUsername, setReturningUsername] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  // Create incomingPosts cache to distinguish posts which need to be rendered in? Merge with posts after animating arrival?
  // Ended up adding post.new boolean to animate new posts received and posted
  // const [incomingPosts, setIncomingPosts] = useState([]);
  const [scrolledToTop, setScrolledToTop] = useState(true);  // Track if the user is at the top
  const [isNewPosts, setIsNewPosts] = useState(false);       // Track if new posts are available
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);
  const { unreadCount, notifications, recentTid } = useLongPolling();

  // // Matomo Tracking Script
  // useEffect(() => {
  //   var _paq = window._paq = window._paq || [];
  //   /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  //   _paq.push(['trackPageView']);
  //   _paq.push(['enableLinkTracking']);
  //   (function() {
  //     var u="//admin.iiicoast.com/";
  //     _paq.push(['setTrackerUrl', u+'matomo.php']);
  //     _paq.push(['setSiteId', '1']);
  //     var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  //     g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  //   })();
  // }, []); // Empty dependency array means this effect runs once on mount


  // Funtion to fetch paginated posts from API
  const fetchPosts = useCallback(async () => {
    console.log('Fetching posts...');
    console.log('Current page:', page);
    setIsLoading(true);

    setTimeout(async () => {
      try {
        console.log('Fetching from API: page', page);
        const response = await axios.get(`https://www.iiicoast.com/api/get-posts/${page}`);
        const newPosts = response.data;
        console.log('Fetched posts:', newPosts);
        
        // setPosts(prevPosts => [...prevPosts, ...newPosts]);

        // Check if there are existing posts
        setPosts(prevPosts => {
          // Ensure newPosts is not empty
          if (newPosts === prevPosts) {
            // If there are no new posts, return the current posts
            return prevPosts;
          }
          // Ensure prevPosts is not empty
          if (prevPosts.length === 0) {
            // If there are no existing posts, just add all new posts
            return [...prevPosts, ...newPosts];
          }

          // Ensure newPosts is not empty
          if (newPosts.length === 0) {
            // If there are no new posts, return the current posts
            return prevPosts;
          }
        
          // Return the updated posts
          return [...prevPosts, ...newPosts];
        });


        setHasMore(newPosts.length > 0);
        setPage(prevPage => prevPage + 1);
        console.log('Next page will be:', page + 1);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setIsLoading(false);
        console.log('Loading finished');
      }
    }, 500); // 0.5-second delay before loading more posts
  }, [page, setPosts]);

  // Function to refresh posts with first page (primarily for reloading homepage on navigate)
  const refreshPosts = useCallback(async () => {
    if (!isAuthenticated) {
      console.log('User is not authenticated. Skipping refresh.');
      return;
    }

    console.log('Refreshing posts...');
    setIsRefreshing(true);

    setTimeout(async () => {
      try {
        const response = await axios.get(`https://www.iiicoast.com/api/get-posts/1`);
        const freshPosts = response.data;
        console.log('Fetched fresh posts:', freshPosts);

        setPosts(freshPosts); // Replace old posts with the latest from page 1
        setPage(2); // Reset to page 2 for the next load
        setHasMore(freshPosts.length > 0);
      } catch (error) {
        console.error('Error refreshing posts:', error);
      } finally {
        setIsRefreshing(false);
        console.log('Refresh finished');
      }
    }, 250); // 0.5-second delay before loading more posts
  }, [isAuthenticated, setPosts]);

  // Function to retrieve new posts from backend with recent_tid (from long-polling)
  const getRecentPost = async (recent_tid) => {
    try {
      // Update to GET to align with best practices. 
      // const response = await axios.get('/v2/get_recent_post', {
      //   params: {
      //     recent_tid: recent_tid
      //   }
      // });
      const response = await axios.post('/v2/get_recent_post', {
        recent_tid: recent_tid,
      });

      if (response.status === 200) {
        // Fetch the new post
        if (response.data) {
          // Add the new post to the top of the list 
          console.log(response.data)
          setPosts(prevPosts => [
            {
              ...response.data,
              new: true
              // animate: true // Add an animate class to trigger the animation
            },
            ...prevPosts
          ]);
          
          // Trim the excess posts to 10
          setPosts(prevPosts => prevPosts.slice(0, 10));
          
          // Reset the page to 2
          setPage(2);
        }
        // setIsLoading(false);
  
        // Smooth scroll to the top
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
  
        console.log('Post retrieved successfully');
      }
      // const freshPosts = response.data;
      // console.log('Fetched fresh posts:', freshPosts);
// 
      // setPosts(freshPosts); // Replace old posts with the latest from page 1
      // setPage(2); // Reset to page 2 for the next load
      // setHasMore(freshPosts.length > 0);
    } catch (error) {
      console.error('Error getting recent post:', error);
    } finally {
      // setIsRefreshing(false);
      console.log('Get recent post finished');
    }
  }

  // Function to submit new post and update posts with /get-user-post API
  const handlePostSubmit = async (content) => {
    try {
      // setIsLoading(true);
      const response = await axiosInstance.post('/v2/post_tweet', {
        tweet: content,
      });
  
      if (response.status === 204) {
        // Fetch the new post
        const newPostResponse = await axiosInstance.get('/v2/get-user-post');
        if (newPostResponse.data) {
          // Add the new post to the top of the list 
          setPosts(prevPosts => [
            {
              ...newPostResponse.data,
              new: true
              // animate: true // Add an animate class to trigger the animation
            },
            ...prevPosts
          ]);
          
          // Trim the excess posts to 10
          setPosts(prevPosts => prevPosts.slice(0, 10));
          
          // Reset the page to 2
          setPage(2);
        }
        // setIsLoading(false);
        
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0; // For older browsers

        // // Smooth scroll to the top
        // window.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });
  
        console.log('Post submitted successfully');
      }
    } catch (error) {
      // setIsLoading(false);
      console.error('Error submitting post:', error);
    }
  }

  const handleNewPostClick = () => {
    // Scroll to the top for iOS Safari Standalone Mode
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For older browsers
    // window.scrollTo({ top: 0, behavior: 'smooth' });  // Scroll to top smoothly
    setScrolledToTop(true);
    setIsNewPosts(false);
    // getRecentPost(recentTid); // Fetch new posts once at the top
  };

  // If no posts and user authenticated load first page of posts
  useEffect(() => {
    if (posts.length === 0 && isAuthenticated) {
      console.log('Initial load: fetching first page of posts');
      fetchPosts();
    }
  }, [posts.length, fetchPosts, isAuthenticated]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user is scrolled to the top
      const isAtTop = window.scrollY === 0;
      setScrolledToTop(isAtTop);
    };
  
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // If posts and latest post id is less than recent_tid (i.e. displayed posts are missing a recent post) then retrieve post with corresponding id
  // Note: current implementation is missing article functionality (articles have different ids from their own table)
  useEffect(() => {
    // If the unread count changes via long polling, reset isDismissed
    // if (unreadCount !== initialUnreadCount) {
    if (posts && posts.length > 0) {
      const lastPost = posts[0];
      if (lastPost && lastPost.post) {
        if (recentTid > lastPost.id) {
          if (scrolledToTop) {
            getRecentPost(recentTid); // Only get posts if scrolled to the top
          } else {
            setIsNewPosts(true); // Show the New Post button
          }
        }
      }
    }
  }, [recentTid, posts, scrolledToTop]);

  console.log(isAuthenticated)

  return (
    <AxiosContext.Provider value={axiosInstance}>
      <Router>
        <GlobalStyles />
        <AppContainer>
          <Sidebar onMinimize={setSidebarMinimized} refreshPosts={refreshPosts} setIsNewPosts={setIsNewPosts}/>
          { isAuthenticated && (
            <NotificationTray unreadCount={unreadCount} notifications={notifications} />
          )}
          {isNewPosts && <NewPostsAlert onClick={handleNewPostClick} />}
          <PageContent $sidebarMinimized={sidebarMinimized}>
            <Header refreshPosts={refreshPosts} />
            <Routes>
              <Route path="/" element={
                <ProtectedRoute>
                  {isRefreshing && (
                    <>
                      <SpinnerContainer>
                        <BarLoader color="#888888" loading={isRefreshing} />
                      </SpinnerContainer>
                    </>
                  )}
                  <>
                    <PostList 
                      posts={posts} 
                      setPosts={setPosts} 
                      page={page} 
                      setPage={setPage}
                      hasMore={hasMore}
                      setHasMore={setHasMore}
                      fetchPosts={fetchPosts}
                      refreshPosts={refreshPosts}
                      isLoading={isLoading}
                    />
                    <FloatingActionButton onSubmit={handlePostSubmit} />
                  </>
                  </ProtectedRoute>
              } />
              <Route path="/profile" element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              } />
              {/* Define route for viewing other users' profiles with a username parameter */}
              <Route path="/profile/:username" element={
                <ProtectedRoute>
                  <ViewProfilePage />
                </ProtectedRoute>
              } />
              <Route path="/explore" element={
                <ProtectedRoute>
                  <ExplorePage />
                </ProtectedRoute>
              } />
              <Route path="/messaging" element={
                <ProtectedRoute>
                  <MessagingPage />
                </ProtectedRoute>
              } />
              <Route path="/music" element={
                <ProtectedRoute>
                  <MusicPage />
                </ProtectedRoute>
              } />
              <Route path="/chatbot" element={<ChatbotPage />} />
              <Route path="/rooms" element={
                <ProtectedRoute>
                  <RoomsPage />
                </ProtectedRoute>
              } />
              <Route path="/thread/:postId" element={
                <ProtectedRoute>
                  <ViewThread />
                </ProtectedRoute>
              } />
              <Route 
                path="/article/:articleId" 
                element={
                  <ProtectedRoute>
                    <ViewArticle />
                  </ProtectedRoute>
                } 
              />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Routes>
          </PageContent>
        </AppContainer>
      </Router>
    </AxiosContext.Provider>
  );
}

export default App;