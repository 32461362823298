import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from './Post';
import { BarLoader } from 'react-spinners';
import { useParams, useLocation } from 'react-router-dom';
import ReplyFloatingActionButton from './ReplyFloatingActionButton';

const ThreadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 25px;
`;

const ReplyContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  border-left: 2px solid #111;
  padding-left: 20px;

  @media (max-width: 768px) {
    margin-left: 15px;
    padding-left: 15px;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

function ViewThread() {
  const { postId } = useParams();
  const location = useLocation();
  const [originalPost, setOriginalPost] = useState(location.state?.post || null);
  const [replies, setReplies] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOriginalPost = useCallback(async () => {
    if (!originalPost) {
        try {
          const response = await axios.get(`https://www.iiicoast.com/api/get-post/${postId}`);
          setOriginalPost(response.data);
        } catch (error) {
          console.error('Error fetching original post:', error);
        }
    }
  }, [postId, originalPost]);

  const fetchReplies = useCallback(async () => {
    setIsLoading(true);
    setTimeout(async () => {
      try {
        const response = await axios.get(`https://www.iiicoast.com/api/get-replies/${page}/${postId}`);
        const newReplies = response.data;
        if (page === 1) {
          setReplies(newReplies);
        } else {
          setReplies(prevReplies => [...prevReplies, ...newReplies]);
        }
  
        if (newReplies.length === 0) {
          setHasMore(false);
        } else {
          setPage(prevPage => prevPage + 1);
        }
      } catch (error) {
        console.error('Error fetching replies:', error);
      } finally {
        setIsLoading(false);
      }
    }, 500);
  }, [postId, page]);

  const handleReplyPosted = useCallback(() => {
    // Reset the page and replies, then fetch the first page of replies again
    setPage(1);
    setReplies([]);
    fetchReplies();
  }, [fetchReplies]);

  useEffect(() => {
    if (!originalPost) {
        fetchOriginalPost();
    }
    // fetchOriginalPost();
    // fetchReplies();
  }, [fetchOriginalPost]);

  useEffect(() => {
    if (replies.length === 0) {
      console.log('Initial load: fetching first page of replies');
      fetchReplies();
    }
  }, [replies.length, fetchReplies]);

  return (
    <ThreadContainer>
      {originalPost && <Post post={originalPost} />}
      <InfiniteScroll
        dataLength={replies.length}
        next={fetchReplies}
        hasMore={hasMore}
        loader={
          <SpinnerContainer>
            <BarLoader color="#888888" loading={isLoading} />
          </SpinnerContainer>
        }
        scrollThreshold={0.9}
      >
        <ReplyContainer>
            {replies.map((reply, index) => (
                <Post 
                  key={`${reply.id}-${reply.timestamp}-${index}`} 
                  post={reply} 
                  isReply={true}
                />
            ))}
        </ReplyContainer>
      </InfiniteScroll>
      {originalPost && (
        <ReplyFloatingActionButton 
          originalPost={originalPost} 
          onReplyPosted={handleReplyPosted} 
        />
      )}
    </ThreadContainer>
  );
}

export default ViewThread;