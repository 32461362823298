import React, { useContext, useState } from 'react';
import { AxiosContext } from '../App';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import styled from 'styled-components';
import { FaHeart, FaComment, FaNewspaper, FaReadme, FaExternalLinkAlt } from 'react-icons/fa';

const BASE_URL = 'https://www.iiicoast.com';

const PostContainer = styled.div`
  background-color: #070707;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border: 2px solid transparent;

  &:hover {
    // box-shadow: 0 14px 28px rgba(28, 28, 28, 0.25), 0 10px 10px rgba(28, 28, 28, 0.22);
    border: 2px solid #111; // Adjust the border color as needed
  }

  @media (max-width: 768px) {
    box-shadow: none;           // Flatter UI
    // border-bottom: 1px solid #111; // Subtle separation
    &:hover {
    // box-shadow: 0 14px 28px rgba(28, 28, 28, 0.25), 0 10px 10px rgba(28, 28, 28, 0.22);
    // border-bottom: 1px solid #transparent; 
    }
  }
`;

const PostHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  margin-bottom: 6px;
  margin-top: 16px;
  align-items: start;
`;

const ProfilePicture = styled.img`
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

const PostInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UsernameDate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    margin-bottom: 4px;
  }
`;

const Username = styled.h3`
  font-size: 0.975rem;
  margin: 0;
  color: #ffffff;
`;

const PostDate = styled.span`
  padding-left: 5px;
  font-size: 0.9rem;
  color: #657786;
`;

const PostContent = styled.p`
  margin: 0;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
`;

const PostFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  color: #657786;
`;

const PostActions = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: #657786;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #ff304f;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  ${props => props.$isLiked && `
    color: #ff304f;
    &:hover {
      color: #ff304f;
    }
  `}
`;

function Post({ post, onLike, isReply = false }) {
  const axiosInstance = useContext(AxiosContext);

  const navigate = useNavigate();

  const [likeCount, setLikeCount] = useState(post.like_count);
  const [isLiked, setIsLiked] = useState(false);

  const profilePicUrl = post.profile_picture_url.startsWith('http') 
    ? post.profile_picture_url 
    : `${BASE_URL}${post.profile_picture_url}`;
  
    const handleLike = async () => {
      if (isLiked) return; // Prevent multiple likes

      try {
        // const userString = localStorage.getItem('user');
        // if (!userString) {
        //   console.error('User not logged in');
        //   return;
        // }
  
        // const user = JSON.parse(userString);
        let response;
        if (isReply) {
          response = await axiosInstance.post('/v2/like_reply', {
            reply_id: post.id,
            tweet_id: post.tid,
            poster_id: post.pid,
            // user_id: user.uid,
            // username: user.username,
            // profile_picture_url: user.profile_picture_url
          });
        } else {
          response = await axiosInstance.post('/v2/like_post', {
            tweet_id: post.id,
            poster_id: post.pid,
            // user_id: user.uid,
            // username: user.username,
            // profile_picture_url: user.profile_picture_url
          });
        }
  
        if (response.status === 204) {
          setLikeCount(prevCount => prevCount + 1);
          setIsLiked(true);
          if (onLike) onLike(post.id);
        }
      } catch (error) {
        console.error('Error liking post:', error);
      }
    };

    const handleCommentClick = () => {
      if (post.post && !isReply) {
        navigate(`/thread/${post.id}`, { state: { post } });
      }
    };

    const handleArticleClick = () => {
      if (post.title) {
        navigate(`/article/${post.id}`, { state: { post } });
      }
    };

  return (
    <PostContainer>
      <PostHeader>
        <Link to={`/profile/${post.username}`}>
          <ProfilePicture src={profilePicUrl} alt={post.username} />
        </Link>
        <PostInfo>
          <UsernameDate>
            <Link to={`/profile/${post.username}`}>
              <Username>{post.username}</Username>
            </Link>
            <PostDate>{post.date}</PostDate>
          </UsernameDate>
          {post.post ? (
            <PostContent>{post.post}</PostContent>
          ) : (
            <PostContent>{post.title}</PostContent>
          )}
        </PostInfo>
      </PostHeader>
      <PostFooter>
      <PostActions>
          {post.post && !isReply ? (
            <ActionButton onClick={handleCommentClick}>
              <FaComment /> {post.reply_count === 1 ? '1 Comment' : post.reply_count > 1 ? `${post.reply_count} Comments` : 'Comment'}
            </ActionButton>
          ) : post.title ? (
            <>
              <ActionButton>
                <FaComment /> Comment
              </ActionButton>
              <ActionButton onClick={handleArticleClick}>
                <FaExternalLinkAlt /> Read
              </ActionButton>
            </>
          ) : null}
          {likeCount !== null && (
            <ActionButton onClick={handleLike} $isLiked={isLiked}>
              <FaHeart /> {likeCount}
            </ActionButton>
          )}
        </PostActions>
      </PostFooter>
    </PostContainer>
  );
}

export default Post;