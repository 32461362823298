import React from 'react';
import styled from 'styled-components';

const MessagingContainer = styled.div`
  padding: 20px;
`;

const MessagingHeader = styled.h1`
  color: #ffffff;
`;

const MessagingPage = () => {
  return (
    <MessagingContainer>
      <MessagingHeader>Messaging</MessagingHeader>
      <p>This is a placeholder for the messaging page. We'll add more details here later!</p>
    </MessagingContainer>
  );
};

export default MessagingPage;