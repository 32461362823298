import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import styled from 'styled-components';
import { FaHome, FaUser, FaChevronLeft, FaChevronRight, FaSearch, FaRegComments, FaMusic, FaRocket, FaTh, FaDoorOpen, FaTimes, FaBars, FaUserPlus, FaDoorClosed } from 'react-icons/fa'; //, FaCog
import useWindowSize from '../hooks/useWindowSize';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ $minimized, $isMobile }) => $isMobile ? ($minimized ? '0' : '100%') : ($minimized ? '40px' : '240px')};
  height: ${({ $isMobile }) => $isMobile ? '100%' : '100vh'};
  background-color: #070707;
  color: white;
  padding: ${({ $isMobile }) => $isMobile ? '60px 10px 20px' : '20px 10px'};
  transition: all 0.3s ease;
  z-index: 1005;
  overflow-x: hidden;

  @media (max-width: 768px) {
    transform: ${({ $minimized }) => $minimized ? 'translateX(-100%)' : 'translateX(0)'};
    width: 100%;
  }
`;

const ExpandButton = styled.button`
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: ${({ $minimized }) => $minimized ? '#000000' : '#070707'}; // #000000; // #070707
  color: white;
  border: none;
  padding: 10px;
  margin-left: ${({ $minimized }) => $minimized ? '7px' : '-7px'}; // #000000; // #070707
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  z-index: 1006;

  @media (max-width: 768px) {
    display: block;
  }

  /* Style for the icons */
  svg {
    padding-top: 2px;
    width: 24px; /* Adjust as needed Added to fix oval with width/height on bg circle may need additional adjustments*/ 
    height: 24px; /* Adjust as needed */
  }
`;

const SidebarLink = styled(NavLink)`
  background: none;
  border: none;
  color: #888888;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-bottom: 20px;
  text-decoration: none;
  transition: color 0.2s ease;
  
  &.active {
    color: #ffffff;
    font-weight: bold;
  }

  &:hover {
    color: #ffffff;
  }

  span {
    font-size: 18px;
    margin-left: 10px;
    display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
  }
`;

const SidebarButton = styled(Link)`
  background: none;
  border: none;
  color: #888888;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-bottom: 20px;
  text-decoration: none;
  transition: color 0.2s ease;
  
  &:hover {
    color: #ffffff;
  }

  span {
    font-size: 18px;
    margin-left: 10px;
    display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
  }
`;

const SidebarButton2 = styled.button`
  background: none;
  border: none;
  color: #888888;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-bottom: 20px;
  transition: color 0.2s ease;

  &:hover {
    color: #ffffff;  // Full white on hover
  }

  span {
    font-size: 18px;
    margin-left: 10px;
    display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
  }
`;

const Sidebar = ({ onMinimize, refreshPosts, setIsNewPosts }) => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    const [minimized, setMinimized] = useState(false);
    const { width } = useWindowSize();
    const isMobile = width <= 768;
  
    const handleMinimize = () => {
      setMinimized(!minimized);
      setIsNewPosts(false)
      onMinimize(!minimized);
    };

    // Ensure the sidebar is minimized on mobile when the component mounts
    useEffect(() => {
      if (isMobile) {
        setMinimized(true);
      }
    }, [isMobile]);
    
    // Combined click handler for the Logout button
    // const handleLogoutClick = () => {
    //   if (isMobile) {
    //     setMinimized(true); // Minimize sidebar on mobile
    //   }
    //   onLogout(); // Perform logout action
    // };

    const handleResetAndNavigate = () => {
      if (isMobile) {
        handleMinimize(); // Call minimize function if on mobile
      }
      refreshPosts();
      // Scroll to top
      window.scrollTo(0, 0);
    };

    const handleLogoutClick = () => {
      if (isMobile) {
        handleMinimize(); // Call minimize function if on mobile
      }
      logout(); // Call the logout function passed from parent
    };

    return (
      <>
        <ExpandButton $minimized={minimized} onClick={handleMinimize}>
          {minimized ? <FaBars /> : <FaTimes />}
        </ExpandButton>
        <SidebarContainer $minimized={minimized} $isMobile={isMobile}>
          {!isMobile && (
            <SidebarButton onClick={handleMinimize}>
              {minimized ? <FaBars /> : <FaTimes />}
            </SidebarButton>
          )}
          <SidebarLink to="/" $minimized={minimized} onClick={handleResetAndNavigate}><FaHome /><span>Home</span></SidebarLink>
          {isAuthenticated && (
            <>
              <SidebarLink to="/explore" $minimized={minimized} onClick={isMobile ? handleMinimize : null}><FaSearch /><span>Explore</span></SidebarLink>
              <SidebarLink to="/profile" $minimized={minimized} onClick={isMobile ? handleMinimize : null}><FaUser /><span>Profile</span></SidebarLink>
              <SidebarLink to="/messaging" $minimized={minimized} onClick={isMobile ? handleMinimize : null}><FaRegComments /><span>Messaging</span></SidebarLink>
              <SidebarLink to="/music" $minimized={minimized} onClick={isMobile ? handleMinimize : null}><FaMusic /><span>Music</span></SidebarLink>
            </>
          )}
        <SidebarLink to="/chatbot" $minimized={minimized} onClick={isMobile ? handleMinimize : null}><FaRocket /><span>Chatbot</span></SidebarLink>
        {isAuthenticated && (
          <>
            <SidebarLink to="/rooms" $minimized={minimized} onClick={isMobile ? handleMinimize : null}><FaTh /><span>Rooms</span></SidebarLink>
            <SidebarButton $minimized={minimized} onClick={handleLogoutClick}><FaDoorOpen /><span>Logout</span></SidebarButton>
          </>
        )}
        {!isAuthenticated && (
          <>
        <SidebarLink to="/register" $minimized={minimized} onClick={isMobile ? handleMinimize : null}>
          <FaUserPlus />
          <span>Register</span>
        </SidebarLink>
        <SidebarLink to="/login" $minimized={minimized} onClick={isMobile ? handleMinimize : null}>
          <FaDoorClosed />
          <span>Login</span>
        </SidebarLink>
        </>
      )}
        
        
        </SidebarContainer>
      </>
    );
  };
  
  export default Sidebar;