import React, { useEffect, useState } from 'react';
import gsap from 'gsap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import UserPostList from '../components/UserPostList';

const BASE_URL = 'https://www.iiicoast.com';

const ProfileCard = styled.div`
  background-color: #070707;
  margin: 15px;
  border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Optional: adds a shadow for a lifted card effect

  @media (max-width: 768px) {
    margin: 5px;
  }
`;

const ProfileContainer = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const CoverPhoto = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
`;

const ProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: -75px;
  border: 5px solid #fff; // Adjust the border color as needed
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  // z-index: 1; /* Ensure profile picture is on top */

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const ProfileHeader = styled.h5`
  margin-top: 15px;
  color: #fff; // Adjust the color as needed
`;

const BioText = styled.p`
  color: #999; // Adjust the color as needed
`;

const ProfileHeaderContainer = styled.div`
  text-align: center;
`;

const ViewProfilePage = () => {
  const { username } = useParams(); // Extract the username parameter from the URL
  const [user, setUser] = useState(null);
  // const username = match.params.username; // Get the username from the route parameters

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`https://www.iiicoast.com/api/user/${username}`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, [username]);

  useEffect(() => {
    if (user) {
    const coverPhoto = document.querySelector('.cover-photo');
    const profilePicture = document.querySelector('.profile_picture');

    gsap.from(coverPhoto, { opacity: 0, y: -50, duration: 0.6, ease: "power1.out" });
    gsap.from(profilePicture, { opacity: 0, scale: 0.5, duration: 0.8, delay: 0.3, ease: "elastic.out(1, 0.3)" });
    }
  }, [user]);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <ProfileCard>
      <ProfileContainer>
        <CoverPhoto src="https://picsum.photos/1600/400?random=cover" alt="Cover Photo" className="cover-photo" />
        <div style={{ textAlign: 'center' }}>
          <ProfilePicture src={`https://www.iiicoast.com/backend-static/${user.profile_picture_url}`} alt="Profile" className="profile_picture" />
          <ProfileHeader>{user.username}'s Bio</ProfileHeader>
          <BioText>{user.bio}</BioText>
      </div>
      <UserPostList username={username} />
      </ProfileContainer>
    </ProfileCard>
  );
};

export default ViewProfilePage;
